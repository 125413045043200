import { styled } from 'Theme/stitches.config';
import { useState } from 'react';
import { API_ROOT_PATH } from 'Shared/Constants/route';
import { z } from 'zod';
import { zodResolver } from '@hookform/resolvers/zod';
import { useForm } from 'react-hook-form';
import InputReactForm from 'DesignComponents/Atoms/Input/InputReactForm';
import PrimaryButton from 'DesignComponents/Atoms/Buttons/PrimaryButton';
import Button from 'Shared/Components/Buttons/Button';
import { FetchCartAndNotifyAll } from 'Commerce/Cart/Cart';
import useMedia from 'Shared/Hooks/useMedia';
import { mediaQueryTypes } from 'Theme/Settings/mediaQueries';
import AddCouponResponse from 'Models/Cart/AddCouponResponse.interface';
import DiscountModel from 'Models/Price/DiscountModel.interface';
import { useAppSettings, useTranslations } from '../../../context/init-data.context';

const ADD_URL = API_ROOT_PATH + '/Cart/AddCoupon';

function PromotionCode() {
  const { languageRoute } = useAppSettings();

  const [isSuccessfull, setIsSuccessfull] = useState(true);
  const [message, setMessage] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [discount, setDiscount] = useState<DiscountModel>();

  const isMobile = useMedia(mediaQueryTypes.bpMax400);

  const {
    errorLabels: { errorFetchingData },
    giftCardLabels: { use },
    productLabels: { discount: discountLabel, discountCode },
    validationLabels: { fillInDiscountCode },
    searchLabels: { subscriptionOffers },
    checkoutLabels: { discountInformation: discountInfoLabel },
  } = useTranslations();

  ///TODO: Add validation for gift card code
  const PromotionCodeConfig = z.object({
    code: z.string().min(2, { message: fillInDiscountCode }),
  });

  interface PromotionCodeConfigType
    extends z.infer<typeof PromotionCodeConfig> {}

  const { formState, getValues, register, handleSubmit, setValue, reset } =
    useForm<PromotionCodeConfigType>({
      resolver: zodResolver(PromotionCodeConfig),
      mode: 'onSubmit',
      defaultValues: {
        code: '',
      },
    });

  const showDiscountInfo = isSuccessfull && !isLoading;

  const submit = async () => {
    setIsLoading(true);

    const payload = getValues();
    const formData = new FormData();
    formData.append('code', payload.code);

    try {
      const res = await fetch(ADD_URL, {
        method: 'POST',
        body: formData,
      });

      if (res.ok) {
        const { isSuccessful, message, discount } =
          (await res.json()) as AddCouponResponse;
        await FetchCartAndNotifyAll(languageRoute);
        setIsSuccessfull(isSuccessful);
        setMessage(message);
        setDiscount(discount);
      } else {
        // Handle error
        setMessage(errorFetchingData);
      }
    } catch (error) {
      // Handle error
      setMessage(errorFetchingData);
    }

    setIsLoading(false);
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.value.length === 0) {
      reset();
      setIsSuccessfull(true);
    } else {
      setValue('code', e.target.value);
    }
  };

  const discountInfo = () => {
    const payload = getValues();
    if (showDiscountInfo && payload.code.length > 0 && isSuccessfull) {
      return (
        <table>
          <tbody>
            <tr>
              <DiscountTitleLabel scope="row">
                {subscriptionOffers}:
              </DiscountTitleLabel>
              <DiscountTitle>{discount?.rewardName}</DiscountTitle>
            </tr>
            <tr>
              <DiscountAmountLabel>{discountLabel}:</DiscountAmountLabel>
              <DiscountAmount>
                -{discount?.totalDiscount.priceAsString}{' '}
                <Currency>{discount?.totalDiscount.currencySymbol}</Currency>
              </DiscountAmount>
            </tr>
          </tbody>
        </table>
      );
    }
  };

  const errorMessage = !isSuccessfull
    ? message
    : formState?.errors.code?.message;

  return (
    <DiscountWrapper>
      <DiscountForm>
        <InputReactForm
          title={discountCode}
          placeholder={discountCode}
          error={!isSuccessfull || !!formState?.errors.code}
          errorText={errorMessage}
          required
          type="text"
          name="code"
          register={register}
          onChange={handleInputChange}
        />
        <Button<typeof PrimaryButton>
          element={'PrimaryButton'}
          props={{
            text: use,
            color: 'Regular',
            hug: isMobile ? 'width' : 'height',
          }}
          css={{ flexShrink: 0 }}
          isLoading={isLoading}
          onClick={handleSubmit(submit)}
        />
      </DiscountForm>

      <DiscountInfo
        aria-label={discountInfoLabel}
        aria-live="assertive"
        aria-atomic="true"
      >
        {discountInfo()}
      </DiscountInfo>
    </DiscountWrapper>
  );
}

const width = {
  maxWidth: '600px',
  marginLeft: 'auto',
  marginRight: 'auto',
};

const DiscountWrapper = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  ...width,
  mt: 0,
  mx: 1,
  pb: 0,
  color: '$onSurface',
});

const DiscountForm = styled('form', {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'start',
  maxWidth: '100%',
  width: '100%',
  mt: 3,
  mb: 0,
  gap: '0',
  '& input[type=text]': {
    width: '100%',
    maxWidth: '100%',
  },
  '& span[role=alert]': {
    maxWidth: '100%',
  },

  '@bpMin401': {
    flexDirection: 'row',
    gap: '8px',
    '& button': {
      position: 'relative',
      top: '24px',
    },
  },
});

const DiscountInfo = styled('section', {
  mb: 7,
  '& p': {
    mb: 3,
  },
});

const DiscountTitleLabel = styled('th', {
  verticalAlign: 'top',
  fontSize: '$fontSize100',
  fontWeight: '$fontWeightSRegular',
  textAlign: 'left',
});

const DiscountTitle = styled('td', {
  verticalAlign: 'top',
  fontSize: '$fontSize200',
  fontFamily: 'fontSemiBold',
});

const DiscountAmountLabel = styled('th', {
  verticalAlign: 'top',
  fontSize: '$fontSize100',
  fontWeight: '$fontWeightSRegular',
  textAlign: 'left',
});

const DiscountAmount = styled('td', {
  verticalAlign: 'top',
  fontSize: '$fontSize200',
  fontFamily: 'fontSemiBold',
});

const Currency = styled('span', {
  fontSize: '$fontSize75',
});

export default PromotionCode;
